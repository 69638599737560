<template>
  <section class="main-head-section">
    <div class="container">
      <div class="main-head">
        <h1>
          A Decentralized Options<br/><span>Order book</span> Trading Protocol
        </h1>
        <p>
          Options on the most volatile tokens on BSC, Polygon and Aurora/Near. (BTC, ETH, BNB, DOT, AAVE, DLTA...)
        </p>
        <div class="head-btns-group">
          <a
            href="https://app.deltatheta.tech"
            class="btn btn_primary"
            target="_blank"
          >
            <span>Launch app</span>
            <svg class="icon icon-share">
              <use xlink:href="@/assets/img/sprite.svg#share"></use>
            </svg>
          </a>
          <a
            href="https://t.me/deltatheta"
            target="_blank"
            class="btn btn_ghost" 
          >
            <span>Join Telegram</span>
            <svg class="icon icon-share">
              <use xlink:href="@/assets/img/sprite.svg#share"></use>
            </svg>
          </a>
          <a
            href="https://liptest-app.deltatheta.tech"
            target="_blank"
            class="btn btn_ghost" 
          >
            <span>MM LIP Terminal</span>
            <svg class="icon icon-share">
              <use xlink:href="@/assets/img/sprite.svg#share"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="main-terminal-img">
    <div class="container">
      <div class="terminal-img-wrapp">
        <div class="hint _left">
          You get additional APY through LIP staking when you are an options seller.
        </div>

        <div class="hint _right">
          <span class="text-color_white">You can buy and SELL options</span> - You
          can place market and LIMIT orders. Use DLTA token to get better fees.
        </div>
        <img src="@/assets/img/terminal.svg" alt="" />
      </div>
    </div>
  </section>

  <section class="service">
    <div class="container">
      <ul class="service-icons">
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-1.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              Full DLTA token integration
            </span>
            - Pay network and platform fees in DLTA and get up to 50% discount;
          </p>
        </li>
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-2.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              Flash Listing
            </span>
            - Request any token on the supported blockchain
            and we will add it within a matter of an hour;
          </p>
        </li>
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-3.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              American style options
            </span>
            - American option allows holders to exercise their
            rights at any time before and including the expiration date.
          </p>
        </li>
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-4.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              Only 100% covered options
            </span>
            - Option sellers locks 100% of the notional value upon order creation;
          </p>
        </li>
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-2.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              Limit and Market orders
            </span>
            - Place orders with your price and volumes through LIMIT orders.
            If you don't see desirable markets, ping us in TG.
          </p>
        </li>
        <li>
          <span class="service-icon">
            <img src="@/assets/img/s-icon-1.png" alt="" />
          </span>
          <p>
            <span class="text-color_dark">
              Liquidity Incentivization Program
            </span>
            - Option sellers can take part in the cross-protocol solution and
            earn APR on the locked liquidity.
          </p>
        </li>
      </ul>
    </div>
  </section>

  <section class="start-trading">
    <div class="container">
      <!-- <div class="start-trading-head">
        <div class="star-trading-head__about">
          <h2>Start Trading Options</h2>
          <p>
            Here are the hottest and most quoted markets of the recent days:
          </p>
        </div>

        <div class="start-trading-head__aside">
          Network
          <div class="select" :class="{ _opened: isSelectOpen }">
            <div class="select-active" @click="isSelectOpen = !isSelectOpen">
              <img src="@/assets/logos/ethereum.svg" alt="" />
              <span>Ethereum</span>
              <svg class="icon icon-chevron-down">
                <use xlink:href="@/assets/img/sprite.svg#chevron-down"></use>
              </svg>
            </div>

            <ul class="select-list">
              <li>
                <img src="@/assets/logos/ethereum.svg" alt="" />
                <span>Ethereum</span>
              </li>
              <li>
                <img src="@/assets/logos/bsc.svg" alt="" />
                <span>BSC (Binance Smart Chain)</span>
              </li>
              <li>
                <img src="@/assets/logos/polygon.svg" alt="" />
                <span>Polygon</span>
              </li>
              <li>
                <img src="@/assets/logos/avalanche.svg" alt="" />
                <span>Avalanche</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tarding-list-wrapp">
        <div class="mobile-scroll-wrapper">
          <div class="trading-list">
            <div
              class="st-radius-bl trading-item"
              v-for="(item, i) of 5"
              :key="`item_${i}`"
            >
              <span class="currency-ic _md">
                <span class="currency-ic__icon _pair">
                  <img src="@/assets/img/eth.svg" alt="" />
                  <img src="@/assets/img/dai.svg" alt="" />
                </span>
                <span class="currency-ic__text">ETH-BUSD</span>
              </span>

              <ul class="trading-item__info">
                <li>
                  <b>Orders</b>
                  <span>2,161</span>
                </li>
                <li>
                  <b>OI</b>
                  <span>943</span>
                </li>
                <li>
                  <b>Vol</b>
                  <span>$1.3m</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> -->

      <div class="trading-stats-wrapp"
        style="border-top: 0; padding-top: 0"
      >
        <div class="mobile-scroll-wrapper">
          <ul class="trading-stats">
            <li>
              <div class="trading-stat-val">4,462+</div>
              <div class="trading-stat-about">
                <span class="text-color_dark">Transactions</span> count
              </div>
            </li>
            <li>
              <div class="trading-stat-val">$941,730.17+</div>
              <div class="trading-stat-about">
                Monthly trading <span class="text-color_dark">volume</span>
              </div>
            </li>
            <li>
              <div class="trading-stat-val">2</div>
              <div class="trading-stat-about">
                Active <span class="text-color_dark">networks</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="terminal-bl st-radius-bl">
        <div class="title-site title-site_h2">Terminal</div>
        <p>
          The heart and soul of
          <span class="text-color_dark">delta.theta</span>. This is the place
          where every user can create limitless options of any type and interact
          with other members in a secure and anonymous way.
        </p>
        <a href="https://app.deltatheta.tech" target="_blank">Launch app</a>
      </div>

      <div class="half-row">
        <div class="half-delta-bl">
          <div class="half-delta-head">
            <span class="currency-ic _md">
              <span class="currency-ic__icon _pair">
                <img src="@/assets/logos/delta.svg" alt="" />
              </span>
              <span class="currency-ic__text">DLTA</span>
            </span>
            <span class="currency-ic-aside">delta.theta</span>
          </div>
          <p>
            A utility token that is deeply integrated into the platform. Get
            discounts on
            <span class="text-color_white">platform commissions</span> as a
            holder, pay platform commission in
            <span class="text-color_white">DLTA</span> with an additional
            discount, pay
            <span class="text-color_white">network commission</span> in DLTA, as
            well as buy unique
            <span class="text-color_white">market analytics</span> from
            delta.theta
          </p>
          <a href="https://coinmarketcap.com/en/currencies/delta-theta" target="_blank">More</a>
        </div>

        <!-- <div class="half-bl st-radius-bl">
          <div class="title-site title-site_h2">Farming</div>
          <p>
            <span class="text-color_dark">Stake $DLTA</span> and get up to
            <span class="text-color_dark">60% APR</span> for your loyalty!
          </p>
          <p>
            Take part in the <span class="text-color_dark">$DLTA Farm!</span>
          </p>
          <div class="half-img">
            <img src="@/assets/img/stake.svg" alt="" />

            <div class="staking-land-wrapp">
              <div class="staking-main-info">
                <ul class="staking-info-list">
                  <li>
                    <div class="st-min-title">Total deposits</div>
                    <div class="st-val">
                      <span>$125 950 321,74</span>
                    </div>
                  </li>
                  <li>
                    <div class="st-min-title">Total withdrawned</div>
                    <div class="st-val">
                      <span>$15 340 234,11</span>
                    </div>
                  </li>
                  <li>
                    <div class="st-min-title">Current APR</div>
                    <div class="st-val">
                      <span class="text_primary">20.0%</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="staking-progress-wrapp">
                <div class="staking-progress-val">
                  <span>0</span>
                  <span>3mln</span>
                  <span>7mln</span>
                </div>

                <div class="staking-progress">
                  <div class="progress-bl">
                    <div
                      class="progress-bl__line"
                      :style="{
                        width: '50%',
                      }"
                    ></div>
                  </div>
                  <div class="progress-bl">
                    <div
                      class="progress-bl__line"
                      :style="{
                        width: '0',
                      }"
                    ></div>
                  </div>
                </div>

                <div class="staking-progress-val">
                  <span class="text_primary">20%</span>
                  <span>40%</span>
                  <span>60%</span>
                </div>
              </div>
            </div>
          </div>
          <a href="https://farm.deltatheta.tech" target="_blank">Launch app</a>
        </div> -->
      </div>
    </div>
  </section>

  <section class="partners">
    <div class="container">
      <div class="partners-head">
        <div class="title-site title-site_h2">Partners and investors</div>
        <p>Those who help expand the delta.theta project</p>
      </div>
      <ul class="partners-logos">
        <!-- <li><img src="@/assets/logos/polygon_color.svg" alt="Polygon" /></li> -->
        <!-- <li><img src="@/assets/img/b-logo-3-png.png" alt="Incrypted" /></li>
        <li><img src="@/assets/img/b-logo-2.svg" alt="3Commas" /></li>
        <li><img src="@/assets/img/b-logo-4.svg" alt="cp0x" /></li> -->
        <li><img src="@/assets/logos/F10_company_logo.png" alt="F10"></li>
        <li><img src="@/assets/logos/finsight.svg" alt="F10"></li>
        <li><img src="@/assets/logos/consensys.png" alt="F10"></li>
        <!-- <li><img style="height: 26px" src="@/assets/img/b-logo-5.svg" alt="Hacken" /></li>
        <li><img style="height: 28px" src="@/assets/img/b-logo-6.svg" alt="fireblocks" /></li> -->
      </ul>
    </div>
  </section>

  <!-- <section class="reviews">
    <div class="container">
      <div class="reviews-head">
        <h2>The opinion of professionals</h2>
        <p>See what the professionals say about delta.theta</p>
      </div>

      <div class="reviews-list-wrapper">
        <div
          class="reviews-list"
          ref="swiperNode"
          @scroll="changeScrollPositonWidth"
        >
          <div
            class="reviws-item"
            v-for="(review, i) of 5"
            :key="`review_${i}`"
          >
            <div class="reviews-item-inner st-radius-bl _shadow-none">
              <p>
                <span class="text-color_dark"
                  >“We were amazed at how easy it was to make our app</span
                >
                in a few hours without knowing how to code, and at how the app
                has modernized our workflow.”
              </p>
              <div class="review-user">
                <img src="@/assets/img/user.png" alt="" />
                <span>Kelly Consuegra, Marves Industries</span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-nav">
          <button
            type="button"
            class="btn btn_icon btn_sm btn_ghost swiper-prev"
            data-swiper="prev"
            @click="swipeSwiper"
            v-show="swiperStartPos"
          >
            <svg class="icon icon-chevron-left">
              <use xlink:href="@/assets/img/sprite.svg#chevron-left"></use>
            </svg>
          </button>
          <button
            type="button"
            class="btn btn_icon btn_sm btn_ghost swiper-next"
            data-swiper="next"
            @click="swipeSwiper"
            v-show="swiperEndPos"
          >
            <svg class="icon icon-chevron-right">
              <use xlink:href="@/assets/img/sprite.svg#chevron-right"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
export default {
  data() {
    return {
      isSelectOpen: false,
      swiperScrollPosition: 0,
      swiperScrollWidth: 1,
    }
  },
  computed: {
    swiperStartPos() {
      return this.swiperScrollPosition > 0
    },
    swiperEndPos() {
      return this.swiperScrollPosition < this.swiperScrollWidth
    },
  },
  methods: {
    swipeSwiper(event) {
      const btnAttrName = event.currentTarget.getAttribute('data-swiper')
      const $swiperNode = this.$refs.swiperNode

      const $swiperItems = $swiperNode.querySelectorAll('.reviws-item')
      const SWIPER_ITEMS_SCROLL = 1
      const scrollStepWidth = $swiperItems[0].offsetWidth * SWIPER_ITEMS_SCROLL

      $swiperNode.scrollTo({
        top: 0,
        left:
          btnAttrName === 'next'
            ? $swiperNode.scrollLeft + scrollStepWidth
            : $swiperNode.scrollLeft - scrollStepWidth,
        behavior: 'smooth',
      })

      this.changeScrollPositonWidth()
    },

    changeScrollPositonWidth() {
      const $swiperNode = this.$refs.swiperNode
      const documentStyle = window.getComputedStyle(document.body)
      const offsetSwiperWidth = parseInt(
        documentStyle.getPropertyValue('--container-space'),
        10
      )

      this.swiperScrollWidth =
        $swiperNode.scrollWidth - $swiperNode.offsetWidth - offsetSwiperWidth
      this.swiperScrollPosition = $swiperNode.scrollLeft
    },
  },
}
</script>
